<template>
    <div class="main-container">

        <Header />

        <Breadcrumb :items="items" title="欢迎加入恋胜" />

        <ContactMainWrapper />

        <!-- <BrandCarousel addClass="grey-bg" /> -->

        <Footer />

        <OffCanvasMobileMenu />

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>

    import Header from '@/components/Header';
    import Breadcrumb from '../components/Breadcrumb'
    import ContactMainWrapper from '../components/sections/ContactMainWrapper'
    import BrandCarousel from '../components/BrandCarousel'
    import Footer from '../components/Footer'
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';

    export default {
        components: {
            Header,
            Breadcrumb,
            ContactMainWrapper,
            BrandCarousel,
            Footer,
            OffCanvasMobileMenu
        },
        data() {
            return {
                items: [
                    {
                        text: '首页',
                        to: "/"
                    },
                    {
                        text: '联系我们',
                        active: true
                    }
                ]
            }
        },
        metaInfo: {
            title: '恋胜 - 联系我们',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        }
    }
</script>

